<template>
  <ClientOnly>
    <a :href="switchLocalePath(localeToSwitch)" class="language-switcher">
      <span aria-hidden="true"> PL/EN </span>
      <span class="visually-hidden" :lang="localeToSwitch">{{
        t('menu.change-language')
      }}</span>
    </a>
    <template #fallback>
      <span class="language-switcher">PL/EN</span>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
const { locale, t } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const localeToSwitch = computed(() => (locale.value === 'en' ? 'pl' : 'en'))
</script>

<style lang="scss" scoped>
.language-switcher {
  @include center-content(vertically);

  @include t-caption-small;
  @include focus-visible;

  @include media-up(xl) {
    @include t-caption;
  }
}
</style>
