<template>
  <button
    :class="{ 'hamburger--active': active }"
    :aria-label="active ? t('menu.close') : t('menu.open')"
    class="hamburger"
  >
    <span v-for="i in 2" :key="i" class="bar" />
  </button>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{ active: boolean }>(), { active: false })

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.hamburger {
  @include focus-visible;
  @include press-area(rem(32px));
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  width: rem(32px);
  height: rem(16px + 8px);
}

.bar {
  position: absolute;
  display: block;
  width: rem(32px);
  height: rem(4px);
  background-color: $black;
  transition: all $transition;

  &:nth-child(1) {
    top: rem(4px);
    transform-origin: 28% 50%;

    .hamburger--active & {
      transform: translateX(6%) rotate(45deg);
    }
  }

  &:nth-child(2) {
    bottom: rem(4px);
    transform-origin: 20% 50%;

    .hamburger--active & {
      transform: translateX(7%) rotate(-45deg);
    }
  }
}
</style>
