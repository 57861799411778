<template>
  <aside :class="{ 'sidebar--active': active }" class="sidebar">
    <div class="nav-overflow">
      <div class="nav-wrapper">
        <nav class="nav grid">
          <StyledCollapse
            v-model="activeItem"
            :items="menu?.items || []"
            :top-count="2"
            class="nav-list"
          >
            <template #top>
              <BaseButton
                v-if="menu?.primary_cta_button"
                :base-link="menu.primary_cta_button.link"
                :text="menu.primary_cta_button.link?.label"
                :style="{ '--order': 0 }"
                color="primary_dark"
                class="top-button"
                @click="closeSidebar"
              />
              <BaseButton
                v-if="menu?.secondary_cta_button"
                :base-link="menu.secondary_cta_button.link"
                :text="menu.secondary_cta_button.link?.label"
                :style="{ '--order': 1 }"
                color="secondary"
                class="top-button"
                @click="closeSidebar"
              />
            </template>

            <template #link="{ item, elClass }">
              <BaseLink
                v-if="item.link"
                :link="{
                  ...item.link,
                  label: item.label || item.link.label || ''
                }"
                class="t-caption"
                :class="elClass"
                @click="closeSidebar"
              >
                <span class="label">{{ item.label }}</span>
              </BaseLink>
              <span v-else class="t-caption" :class="elClass">
                <span class="label">{{ item.label }}</span>
              </span>
            </template>

            <template #default="{ subItem, elClass, isListActive }">
              <NavigationSubItem
                :class="elClass"
                :item="subItem"
                :tabindex="isListActive ? undefined : -1"
                :aria-hidden="!isListActive"
                @click="closeSidebar"
              />
            </template>
          </StyledCollapse>
        </nav>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { PublicMainMenu } from '@/service/__generated-api'

const emit = defineEmits(['close'])
const props = withDefaults(
  defineProps<{ menu: Partial<PublicMainMenu> | null; active?: boolean }>(),
  {
    active: false
  }
)

const activeItem = ref<string[]>([])

watch(
  () => props.active,
  value => {
    if (value) return
    activeItem.value = []
  },
  { immediate: true }
)

const closeSidebar = () => {
  emit('close')
  activeItem.value = []
}
</script>

<style lang="scss" scoped>
.sidebar {
  @include hide-up(xl);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-top: $nav-top-section-height;
  overflow-y: auto;
  color: $pure-white;
  visibility: hidden;
  transition:
    transform 0s 0.5s,
    visibility 0s 0.55s;

  transform: translateY(-100%);

  &::before {
    @include fill-space($position: fixed);
    content: '';
    background-color: $orange;
  }

  &::before,
  .nav {
    transition: transform $dynamic-transition;
    transform: translateY(-100%);
  }

  :deep(li:not(.top)) {
    transition: all 0.4s;
  }

  .top-button,
  .top-button:deep(.text) {
    $delay: calc(var(--order) * 0.05s + 0.4s);

    transition:
      background-color 0.2s ease-out 0s,
      color 0.2s ease-out 0s,
      border-color 0.2s ease-out 0s,
      opacity 0.4s $delay,
      transform 0.4s $delay;
  }

  :deep(li:not(.top)),
  .top-button,
  .top-button:deep(.text) {
    opacity: 0;
    transform: translateY(-2rem);
  }

  &:not(&--active) {
    :deep(li:not(.top)),
    .top-button,
    .top-button:deep(.text) {
      transition-delay: unset !important;
    }
  }

  &--active {
    visibility: visible;
    transition:
      transform 0s,
      visibility 0s;

    &,
    &::before,
    .nav,
    :deep(li:not(.top)),
    .top-button,
    .top-button:deep(.text) {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

.nav-overflow {
  overflow: hidden;
}

.nav-wrapper {
  height: 100%;
  overflow-y: auto;
}

.nav-list {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  padding-bottom: rem(54px);

  @include media-up(md) {
    padding-bottom: rem(51px);
  }
}

.top-button,
.top-button :deep(*) {
  user-select: none;
  @supports (-webkit-touch-callout: none) {
    -webkit-touch-callout: none;
  }
}

.button {
  padding: rem(14px) rem(22px);
  font-size: rem(22px);
  line-height: 0.90909;
}
</style>
