<template>
  <CollapseGroup
    id="menu"
    v-model="modelValue"
    tag="ul"
    class="list alternative-focus-outline"
  >
    <li
      v-if="hasDivider"
      class="divider"
      aria-hidden="true"
      :style="{
        order: dividerPosition,
        transitionDelay: `${dividerPosition * 0.05 + 0.4}s`
      }"
    />
    <li class="top">
      <slot name="top" />
    </li>
    <Component
      :is="withSublist(item) ? Collapse : 'li'"
      v-bind="withSublist(item) ? { tag: 'li', id: `menu_${i}` } : {}"
      v-for="(item, i) in items"
      :key="i"
      ref="listitem"
      :style="{
        order: item.is_after_divider ? i + topCount + 1 : i + topCount,
        transitionDelay: `${
          (item.is_after_divider ? i + topCount + 1 : i + topCount) * 0.05 + 0.4
        }s`
      }"
      :class="{
        'list-item--with-sublist': withSublist(item)
      }"
      class="list-item"
    >
      <CollapseTitle v-if="withSublist(item)" class="collapse-title">
        <slot name="link" el-class="link" :item="item" />
      </CollapseTitle>
      <slot v-else name="link" el-class="link" :item="item" />

      <CollapseContent
        v-if="withSublist(item)"
        v-slot="{ isActive }"
        class="collapse"
      >
        <Component :is="forceSublist ? 'div' : 'ul'" class="collapsed-list">
          <Component
            :is="forceSublist ? 'div' : 'li'"
            v-for="(subItem, index) in item.items"
            :key="index"
            class="sub-link-item"
          >
            <slot
              el-class="sub-link"
              :sub-item="subItem"
              :is-list-active="isActive"
            />
          </Component>
        </Component>
      </CollapseContent>
    </Component>
  </CollapseGroup>
</template>

<script setup lang="ts">
import {
  PublicMenuItem,
  PublicSecondLevelMenuItem
} from '@/service/__generated-api'

const props = withDefaults(
  defineProps<{
    items: PublicMenuItem[]
    forceSublist?: boolean
    topCount?: number
  }>(),
  { forceSublist: false, topCount: 0 }
)

const modelValue = defineModel<string[]>()

const Collapse = resolveComponent('Collapse')
const CollapseContent = resolveComponent('CollapseContent')
const withSublist = (item: PublicMenuItem | PublicSecondLevelMenuItem) =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  ('items' in item && item.items?.length) || props.forceSublist

const dividerPosition =
  props.items.filter(item => !item.is_after_divider).length + props.topCount
const hasDivider = props.items.length + props.topCount !== dividerPosition
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
  width: 100%;
  margin-top: rem(48px);

  @include media-up(md) {
    margin-top: rem(32px);
  }
}

.list-item {
  $gap: rem(16px);
  $padding: rem(12px);
  $icon-width: rem(27.5px);

  @include center-content;
  flex-direction: column;
  align-items: flex-start;

  &--with-sublist:deep(.link) {
    @include center-content;
    gap: $gap;

    &::before {
      position: absolute;
      top: rem(-4px);
      left: $padding;
      width: calc(100% - $icon-width - $gap - $padding * 2);
      height: rem(3px);
      content: '';
      background: $black;
      opacity: 0;
      transition:
        opacity $transition,
        transform 0.4s ease-out;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    &::after {
      display: block;
      width: $icon-width;
      height: rem(15.1px);
      margin-top: -0.15em;
      content: '';
      background-image: url('/icons/arrow-down.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;

      transition: transform $transition;
    }
  }

  &:deep(.link) {
    position: relative;
    padding: $padding;
    margin: -#{$padding};
    color: $black;

    @include media-down(xl) {
      @include font-size(
        (
          xs: 32px 0.9375,
          md: 38px
        )
      );
    }
  }

  &:deep(.link),
  &:deep(.sub-link) {
    @include focus-visible;
  }

  &:deep(.sub-link) {
    color: $black;

    .label {
      @include font-size(
        (
          xs: 15px 1.46667,
          md: 18px 1.22222
        )
      );
    }
  }
}

:deep(.collapse--expanded) .link {
  &::before {
    opacity: 1;
    transform: scaleX(1);
  }

  &::after {
    transform: rotateX(-180deg);
  }
}

.collapse {
  width: 100%;
}

.collapsed-list {
  display: flex;
  flex-direction: column;
  gap: rem(11px);
  padding: rem(27px) 0 rem(24px);
}

.divider {
  @include size(rem(22px), rem(3px));
  margin: rem(24px) 0;
  background-color: $black;
}

.top {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
  order: 0;
  margin-bottom: rem(36px);

  @include media-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<style lang="scss">
.collapse-title:focus-visible .link {
  @include focus-outline;
}
</style>
