<template>
  <footer class="footer grid">
    <div class="inner t-caption-small">
      <div class="top">
        <NavigationSocial />
        <BaseImage
          v-if="settings?.secondary_logotype"
          class="logo"
          :image="settings.secondary_logotype"
        />
      </div>
      <div class="bottom">
        <ul class="links">
          <li>{{ t('copyrights-by-alter-art') }}</li>
          <li v-if="settings?.privacy_policy_link">
            <BaseLink :link="settings.privacy_policy_link" class="link">
              {{ t('privacy-policy') }}
            </BaseLink>
          </li>
          <li v-if="settings?.contact_page_link">
            <BaseLink :link="settings?.contact_page_link" class="link">
              {{ t('contact') }}
            </BaseLink>
          </li>
        </ul>
        <div class="partners" v-html="t('design-and-development')" />
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useWebsiteSettings } from '@/state/websiteSettings'

const { t } = useI18n()
const settings = await useWebsiteSettings()
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  // cover pixels in the background
  z-index: 1;
  padding-top: rem(64px);
  padding-bottom: rem(24px);
  background-color: $white;

  @include media-up(md) {
    padding-bottom: rem(64px);
  }

  @include media-up(lg) {
    padding-bottom: rem(48px);
  }
}

.inner {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.links {
  display: flex;
  flex-direction: column;
  gap: rem(8px);

  @include media-up(lg) {
    flex-direction: row;
    gap: rem(48px);
  }
}

.top {
  display: flex;
  flex-direction: column;
  gap: rem(32px);
  align-items: center;
  margin-bottom: rem(64px);

  @include media-up(md) {
    gap: rem(48px);
  }
  @include media-up(lg) {
    gap: rem(64px);
  }
}

.logo {
  max-width: rem(302px);

  @include media-up(md) {
    max-width: rem(365px);
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: rem(32px);

  @include media-up(md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.link,
.partners :deep(a) {
  @include focus-visible;
  position: relative;

  &::before {
    position: absolute;
    top: rem(-8px);
    left: 0;
    width: 100%;
    content: '';
    border-bottom: $base-border;
    opacity: 0;
    transition:
      opacity $transition,
      transform 0.4s ease-out;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }

  &:hover,
  &:focus-within {
    &::before {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}
</style>
