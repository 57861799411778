const detectMobile = () =>
  navigator.userAgent.match(/Android/i) ??
  navigator.userAgent.match(/webOS/i) ??
  navigator.userAgent.match(/iPhone/i) ??
  navigator.userAgent.match(/iPad/i) ??
  navigator.userAgent.match(/iPod/i) ??
  navigator.userAgent.match(/BlackBerry/i) ??
  navigator.userAgent.match(/Windows Phone/i)

export function useIsMobile() {
  const isMobile = useState('isMobile', () =>
    import.meta.client && detectMobile() ? 'is-mobile' : ''
  )

  const setMobileState = () => {
    isMobile.value = import.meta.client && detectMobile() ? 'is-mobile' : ''
  }
  onMounted(() => {
    window.addEventListener('resize', setMobileState)
    setMobileState()
  })
  onBeforeUnmount(() => {
    window.removeEventListener('resize', setMobileState)
  })

  useHead({
    htmlAttrs: () => ({
      class: isMobile
    })
  })
}
