<template>
  <NuxtLink
    :to="localePath({ name: 'index' })"
    class="logo"
    :aria-label="t('homepage')"
  >
    <BaseImage
      v-if="settings?.primary_logotype"
      :image="settings.primary_logotype"
      :img-sizes="[
        { w: 70, h: 70, resize: 'fit' },
        { w: 100, h: 100, resize: 'fit' }
      ]"
      sizes="(min-width: 1600px) 100px, 70px"
      fit="fit"
      @load="$emit('load')"
    />
  </NuxtLink>
</template>

<script lang="ts" setup>
import { useWebsiteSettings } from '@/state/websiteSettings'

defineEmits<{ (e: 'load'): void }>()

const { t } = useI18n()
const settings = await useWebsiteSettings()
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.logo {
  @include focus-visible;

  display: block;

  width: rem(70px);
  height: rem(67px);
  cursor: pointer;
  transition: opacity $transition;

  @include media-up(xxl) {
    @include size(rem(80px));
  }

  @include media-up(lg) {
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
