<template>
  <NavigationBar
    :menu="menu"
    :active-menu="isActive"
    @open="openMenu"
    @close="closeMenu"
  />
</template>

<script setup lang="ts">
import { useHeaderSettings } from '@/state/headerSettings'

const menu = await useHeaderSettings()

const isActive = ref(false)

const openMenu = () => {
  isActive.value = true
}
const closeMenu = () => {
  isActive.value = false
}
</script>
