<template>
  <nav
    v-if="breadcrumbs.length"
    :aria-label="t('a11y.breadcrumb')"
    class="breadcrumbs"
  >
    <ol class="list">
      <li class="list-item">
        <NuxtLink
          class="link link--icon"
          :to="localePath({ name: 'index' })"
          :aria-label="t('homepage')"
          ><HomeIcon class="icon" aria-hidden="true" role="img"
        /></NuxtLink>
      </li>
      <li
        v-for="({ text, to }, index) in breadcrumbs"
        :key="`${index}-${text}`"
        class="list-item"
      >
        <BreadcrumbChevronIcon class="icon" aria-hidden="true" role="img" />
        <NuxtLink
          :aria-current="index === breadcrumbs.length - 1 ? 'page' : undefined"
          class="link link--text"
          :to="localePath(to)"
          >{{ text }}</NuxtLink
        >
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import BreadcrumbChevronIcon from '@/assets/icons/breadcrumb-chevron.svg?component'
import HomeIcon from '@/assets/icons/home.svg?component'
import { useBreadcrumbs } from '@/state/breadcrumbs'

const { breadcrumbs } = useBreadcrumbs()

const { t } = useI18n()
const localePath = useLocalePath()
</script>

<style scoped lang="scss">
@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.breadcrumbs {
  position: relative;
  z-index: z(breadcrumbs);
  padding-inline: var(--grid-space-around);
  opacity: 0;

  .route-change & {
    animation: fade-out $transition;
  }

  body:not(.route-change) & {
    animation: 0.5s 0.3s fade-in forwards;
  }

  &::after {
    position: absolute;
    inset: auto var(--grid-space-around) rem(-1px);
    display: block;
    height: rem(2px);
    content: '';
    background-color: $black;
  }
}

.list {
  display: flex;
  gap: rem(12px);
  align-items: center;

  margin-block: rem(14px);
  overflow: hidden;
  list-style: none;
}

.list-item {
  display: flex;
  gap: inherit;
  align-items: inherit;
  min-width: 0;

  &:not(:last-child) {
    flex-shrink: 0;
  }
}

.icon {
  display: block;
  flex-shrink: 0;
}

.link {
  @include focus-visible;
  position: relative;

  &--icon {
    display: grid;
    place-items: center;
    min-width: rem(24px);
    min-height: rem(24px);
  }

  &--text {
    @include focus-visible(-2px);
    @include font-size(
      (
        xs: 12px 1.5,
        md: 14px 1.43
      )
    );
    letter-spacing: -0.03em;
    white-space: nowrap;

    @include media-up(md) {
      padding-block: rem(4px);
    }

    &::after {
      position: absolute;
      inset: auto 0 0 0;
      display: block;
      width: 100%;
      height: rem(2px);
      content: '';
      background-color: transparent;
      transition: background-color $transition;
    }

    &:hover,
    &:focus-visible {
      &::after {
        background-color: $black;
      }
    }

    .list-item:last-child & {
      @include trim-text;
      font-weight: 700;
    }
  }
}
</style>
