<template>
  <div ref="animationBox" />
</template>

<script lang="ts">
export const pixelColors = ['green', 'pink', 'blue', 'violet'] as const
</script>

<script setup lang="ts">
import axios from 'axios'
import lottie, { type AnimationItem } from 'lottie-web'

import { useAnimationDataPromisesMap } from '@/state/pixelAnimationData'

export type PixelColor = (typeof pixelColors)[number]

const animationDataPromisesMap = useAnimationDataPromisesMap()

const props = withDefaults(
  defineProps<{
    id: string
    color: PixelColor
    delay?: number
  }>(),
  { delay: 0 }
)
const emit = defineEmits<{ (e: 'complete'): void }>()

const animationBox = ref<HTMLElement>()
const animation = ref<AnimationItem>()

const initAnimation = async () => {
  if (!animationBox.value) return
  animationDataPromisesMap.value[props.color] ??= axios
    .get(`/animations/${props.color}.json`)
    .then(({ data }) => data)

  animation.value = lottie.loadAnimation({
    name: props.id,
    container: animationBox.value,
    renderer: 'canvas',
    loop: false,
    autoplay: false,
    animationData: await animationDataPromisesMap.value[props.color]
  })

  animationBox.value?.childNodes?.forEach(node => {
    if (node instanceof HTMLElement && node.tagName === 'CANVAS') {
      node.setAttribute('aria-hidden', 'true')
      node.setAttribute('role', 'img')
    }
  })

  setTimeout(() => animation.value?.play(), props.delay)

  animation.value?.addEventListener('complete', () => emit('complete'))
}
const destroyAnimation = () => animation.value?.destroy(props.id)

onMounted(initAnimation)
onBeforeUnmount(destroyAnimation)
</script>
