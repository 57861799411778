/**
 * Format string to slug
 * @param text
 */

export function slugify(text: string) {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s/g, '-')
}
